import { AnyAction, Reducer } from 'redux';
import { Claim, getClaim, getPropertyFromClaim } from '../../../../claim';
import { CommunicationDetails } from '../../../../forms/sub-forms/communication-details';
// import { collectionReducer } from '../../../../redux/collection-reducer';
import { combineReducersWithDefault } from '../../../../redux/combine-reducers-with-default';
import { State } from '../../../../redux/root-reducer';
import { Nullable } from '../../../../shared-types';

export interface OtherDetails {
    highestQualification: string;
    hasInsolvency: string;
    consumesAlcohol: string;
    totCount: string;
    wineGlassCount: string;
    beerCanOrCider: string;
    receivedAdviceToReduceAlcohol: string;
    alcoholAdviceDetails: string;
    practiceAvocation: string;
    practiceAvocationDetails: string;
    hoursWorkedPerWeek: string;
    wasRetired: string;
    retirementFullDetails: string;
}

export interface MedicalAidDetails {
    hasMedicalAid: string;
    isLifeAssuredMainMember: string;
    lifeAssuredMedicalDateJoined: Date;
    lifeAssuredMedicalAidDescription: string;
    lifeAssuredIdNumber: string;
    lifeAssuredMedicalAidName: string;
    lifeAssuredMedicalAidNumber: string;
    lifeAssuredName: string;
    lifeAssuredSurname: string;
    lifeAssuredTitle: string;
}
export interface occurences {
    dateOfAttendance: Date;
    reason: string;
}

export interface AddressDetails {
    readonly addressType?: string;
    readonly addressLineOne?: string;
    readonly addressLineTwo?: string;
    readonly addressLineThree?: string;
    readonly addressLineFour?: string;
    readonly addressSuburb?: string;
    readonly postalCode?: string;
    readonly town?: string;
    readonly province?: string;
    readonly country?: string;
    readonly postalAddressAddressType?: string;
    readonly postalAddressCode?: string;
    readonly postalAddressCountry?: string;
    readonly postalAddressLineOne?: string;
    readonly postalAddressLineThree?: string;
    readonly postalAddressLineTwo?: string;
    readonly postalAddressProvince?: string;
    readonly postalAddressSuburb?: string;
    readonly postalAddressTown?: string;
    // readonly sameAsResidential?: string;
    readonly sameAsResidentialAddress?: boolean;
    readonly postalPostalCode?: string;
}
export interface DoctorVisits {
    addressDetails?: AddressDetails;
    doctorsContactDetails?: string;
    doctorsName?: string;
    emailAddress: string;
    occurences: Array<occurences>;
    practiceNumber: string;
}

export interface MedicalInformaion {
    doctorVisits?: Array<DoctorVisits>;
    extractOfMedicalHistory?: string;
    extractOfMedicalHistory1?: string;
    lifeAssuredAccidentDetails?: string;
    lifeAssuredDateOfDiagnosis?: Date;
    lifeAssuredMedicalCondition?: string;
    lifeAssuredMedicalEvidence?: string;
    lifeAssuredMedicalReason?: string;
    lifeAssuredlistMedicationTreatment?: string;
    takingMedicationOrTreatment?: string;
    lifeAssuredMoreInformation?: string;
}

export interface ContactDetails extends CommunicationDetails {
    sameDetailsAsFa?: boolean;
    homeTelephoneNumber?: string;
    workTelephoneNumber?: string;
}

export interface PersonalDetails {
    companyName?: string;
    consultantCode?: string;
    countryOfBirth?: Date;
    countryOfIssue?: string;
    countryOfResidence?: string;
    dateOfBirth?: Date;
    dateOfExpiry?: Date;
    dateOfIssue?: Date;
    dateOfMarriage?: Date;
    firstName?: string;
    gender?: string;
    idNumber?: string;
    initials?: string;
    isRSAResident?: string;
    maiden?: string;
    maritalStatus?: string;
    passportNumber?: string;
    placeOfBirth?: string;
    proofOfIdentification?: string;
    surname?: string;
    title?: string;
    incomeTaxNumber?: string;
    lifeAssuredCountryOfBirth?: string;
    lifeAssuredCountryOfIssue?: string;
    lifeAssuredCountryOfResidence?: string;
    lifeAssuredDateOfBirth?: Date;
    lifeAssuredDateOfIssue?: Date;
    lifeAssuredDateOfMarriage?: Date;
    lifeAssuredFirstName?: string;
    lifeAssuredGender?: string;
    lifeAssuredIdNumber?: string;
    lifeAssuredIncomeTaxNumber?: string;
    lifeAssuredIsRSAResident?: string;
    lifeAssuredMaritalStatus?: string;
    lifeAssuredPassportNumber?: string;
    lifeAssuredPlaceOfBirth?: string;
    lifeAssuredProofOfIdentification?: string;
    lifeAssuredSurname?: string;
    lifeAssuredTitle?: string;
}

export interface LifeAssuredDetails {
    addressDetails?: AddressDetails;
    contactDetails?: ContactDetails;
    personalDetails?: PersonalDetails;
    otherDetails?: OtherDetails;
    medicalAidDetails?: MedicalAidDetails;
    medicalInformation?: MedicalInformaion;
}

export interface Declaration {
    certifiedBy?: string;
    firstName?: string;
    secondName?: string;
    lastName?: string;
    idNumber?: string;
    telephoneNumber?: string;
    hospitalName?: string;
    hospitalContactNo?: string;
    addressDetails?: AddressDetails;
}

export interface Information {
    accountHolderFirstName?: string;
    accountNumber?: string;
    accountType?: string;
    bankName?: string;
    branchCode?: string;
    branchName?: string;
    companyName?: string;
    companyRegistrationDate?: Date;
    companyRegistrationNumber?: string;
    countryOfIncorporation?: string;
    idNumber?: string;
    isRSAResident?: string;
    paymentBeneficiary?: string;
    policyHolderType?: string;
    relationshipToAccountHolder?: string;
    relationshipToPolicyHolder?: string;
    surname?: string;
}

export interface Payment {
    information?: Information;
}

export interface EmploymentDetails {
    hasTaxNumber: string;
    isPolicyHolderEmployed: string;
    nominatedOccupation: string;
    industryOfOccupation: string;
    taxOffice: string;
    saTaxReferenceNumber: string;
    employerName: string;
}

export interface CompanyDetails {}
export interface Individual {
    addressDetails?: AddressDetails;
    companyDetails?: CompanyDetails;
    contactDetails?: ContactDetails;
    employmentDetails?: EmploymentDetails;
    personalDetails?: PersonalDetails;
}

export interface PolicyHolderDetails {
    individual?: Individual;
    policyHolderType?: string;
}

export interface PolicyDetails {
    claimingCapacity?: string;
    isOwner?: string;
    policyNumber?: string;
}
export interface Claimant {
    contactDetails?: ContactDetails;
    personalDetails?: PersonalDetails;
    policyDetails?: PolicyDetails;
}

export const defaultClaimant: Claimant = {
    contactDetails: {},
    policyDetails: {},
    personalDetails: {},
};

export interface FormDetails {
    lifeAssured?: LifeAssuredDetails;
    declaration?: Declaration;
    payment?: Payment;
    policyHolder?: PolicyHolderDetails;
    claimant?: Claimant;
    declarationInformation?: '';
}

export const defaultFormDetails: FormDetails = {
    lifeAssured: {},
    declaration: {},
    payment: {},
    policyHolder: {},
    claimant: {},
    declarationInformation: '',
};

const defaultFormDetailsList: FormDetails[] = [];

const lifeAssuredReducer: Reducer<LifeAssuredDetails | undefined, AnyAction> = (
    state: LifeAssuredDetails = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const declarationReducer: Reducer<Declaration | undefined, AnyAction> = (
    state: Declaration = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const paymentReducer: Reducer<Payment | undefined, AnyAction> = (
    state: Payment = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const declarationInformationReducer: Reducer<'' | undefined, AnyAction> = (state = '', action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const policyHolderReducer: Reducer<PolicyHolderDetails | undefined, AnyAction> = (
    state: PolicyHolderDetails = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

const claimantReducer: Reducer<Claimant | undefined, AnyAction> = (
    state: Claimant = {},
    action: AnyAction
) => {
    switch (action.type) {
        default:
            return state;
    }
};

export const formDetailsReducer = combineReducersWithDefault<Nullable<FormDetails>>(
    defaultFormDetails,
    {
        lifeAssured: lifeAssuredReducer,
        declaration: declarationReducer,
        payment: paymentReducer,
        policyHolder: policyHolderReducer,
        claimant: claimantReducer,
        declarationInformation: declarationInformationReducer,
    }
);

export const formDetailsListReducer = (
    state: FormDetails[] = [],
    action: AnyAction
): FormDetails[] => {
    switch (action.type) {
        default:
            return state;
    }
};

export function getFormDetails(state: State): FormDetails {
    return getFormDetailsFromClaim(getClaim(state));
}

export function getFormDetailsList(state: State): FormDetails[] {
    return getFormDetailsListFromClaim(getClaim(state));
}

export function getFormDetailsFromClaim(claim: Claim): FormDetails {
    return getPropertyFromClaim(claim, 'formDetails', defaultFormDetails);
}

export function getFormDetailsListFromClaim(claim: Claim): FormDetails[] {
    return getPropertyFromClaim(claim, 'formDetailsList', defaultFormDetailsList);
}

export function getFormDeclarationFromClaim(claim: Claim): string {
    return getPropertyFromClaim(claim, 'declarationInformantion', '');
}

export function getFormDetailsContactDetails(
    claim: Claim,
    financialAdviser: any,
    contactPerson: any
) {
    const formDetails = getPropertyFromClaim(claim, 'formDetails', defaultFormDetails);

    let financialAdviserContact = {};

    if (financialAdviser) {
        financialAdviserContact = {
            telephoneNumber: financialAdviser?.telephoneNumber,
            cellphoneNumber: financialAdviser?.cellphoneNumber,
            faxNumber: financialAdviser?.faxNumber,
            emailAddress: financialAdviser?.emailAddress,
            preferredMethodOfCommunication: financialAdviser.preferredMethodOfCommunication,
            initials: financialAdviser?.initials,
            title: financialAdviser?.title,
            firstName: financialAdviser?.firstName,
            lastName: financialAdviser?.lastName,
            companyName: financialAdviser?.companyName,
            consultantCode: financialAdviser?.consultantCode,
        };
    }

    let contactPersonDetails = {};
    if (contactPerson) {
        contactPersonDetails = {
            telephoneNumber: contactPerson?.sameDetailsAsFa
                ? financialAdviser.telephoneNumber
                : contactPerson.telephoneNumber,
            cellphoneNumber: contactPerson?.sameDetailsAsFa
                ? financialAdviser.cellphoneNumber
                : contactPerson.cellphoneNumber,
            emailAddress: contactPerson?.sameDetailsAsFa
                ? financialAdviser.emailAddress
                : contactPerson.emailAddress,
            faxNumber: contactPerson?.sameDetailsAsFa
                ? financialAdviser.faxNumber
                : contactPerson.faxNumber,
            preferredMethodOfCommunication: contactPerson?.sameDetailsAsFa
                ? financialAdviser.preferredMethodOfCommunication
                : contactPerson.preferredMethodOfCommunication,
            initials: contactPerson?.sameDetailsAsFa
                ? financialAdviser.initials
                : contactPerson?.initials,
        };
    }

    const personalDetails = {
        ...formDetails?.claimant?.personalDetails,
        title:
            formDetails?.claimant?.policyDetails?.claimingCapacity === 'Financial Advisor' ||
            contactPerson?.sameDetailsAsFa
                ? financialAdviser?.title
                : contactPerson?.title,
        firstName:
            formDetails?.claimant?.policyDetails?.claimingCapacity === 'Financial Advisor' ||
            contactPerson?.sameDetailsAsFa
                ? financialAdviser?.firstName
                : contactPerson?.firstName,
        surname:
            formDetails?.claimant?.policyDetails?.claimingCapacity === 'Financial Advisor' ||
            contactPerson?.sameDetailsAsFa
                ? financialAdviser?.lastName
                : contactPerson?.lastName,
        companyName:
            formDetails?.claimant?.policyDetails?.claimingCapacity === 'Financial Advisor' ||
            contactPerson?.sameDetailsAsFa
                ? financialAdviser?.companyName
                : null,
        consultantCode:
            formDetails?.claimant?.policyDetails?.claimingCapacity === 'Financial Advisor' ||
            contactPerson?.sameDetailsAsFa
                ? financialAdviser?.consultantCode
                : null,
    };

    return {
        claimant: {
            contactDetails:
                formDetails?.claimant?.policyDetails?.isOwner === 'Yes' ||
                formDetails?.claimant?.policyDetails?.claimingCapacity === 'Relative'
                    ? contactPersonDetails
                    : financialAdviserContact,
            personalDetails: personalDetails,
            policyDetails: formDetails?.claimant?.policyDetails,
        },
    };
}

export function getContactDetailsFormClaim(claim: Claim) {
    return getPropertyFromClaim(claim, 'contactDetails', {
        financialAdviser: {},
        contactPerson: {},
    });
}

export function getFormDetailsMedicalAidDetails(medicalAidDetails: any) {
    return {
        isLifeAssuredMainMember: medicalAidDetails.isLifeAssuredTheMainMember,
        lifeAssuredMedicalDateJoined: medicalAidDetails.dateJoined,
        lifeAssuredMedicalAidDescription: medicalAidDetails.medicalAidDescription,
        lifeAssuredIdNumber: medicalAidDetails.mainMembersIdNumber,
        lifeAssuredMedicalAidName: medicalAidDetails.medicalAidName,
        lifeAssuredMedicalAidNumber: medicalAidDetails.medicalAidNumber,
        lifeAssuredName: medicalAidDetails.mainMembersFirstName,
        lifeAssuredSurname: medicalAidDetails.mainMembersLastName,
        lifeAssuredTitle: medicalAidDetails.mainMembersTitle,
    };
}
